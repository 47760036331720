import styled from "styled-components";
import { NAV_BAR_HEIGHT } from "./NavBar";

export default styled.div`
  background: ${(p) => p.theme.grayscale[200]};
  position: fixed;
  bottom: ${NAV_BAR_HEIGHT};
  left: 0;
  height: ${(p) => p.theme.stroke[400]};
  width: 100%;
  z-index: 10;
`;
