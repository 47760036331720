import { t } from "~/lang";
import { ThemeProvider } from "styled-components";
import styled from "styled-components";
import Headline from "../copys/Headline";

export default function GameCard({ gameData }) {
  return (
    <ThemeProvider theme={gameData.theme}>
      <Wrapper>
        <Content>
          <Headline size="sm">{t(gameData.slug)}</Headline>
        </Content>
        <CoverImage src={`/assets/games/${gameData.slug}/covers/portrait.svg`} alt={t(gameData.slug)} />
      </Wrapper>
    </ThemeProvider>
  );
}

const Wrapper = styled.div`
  background: ${(p) => p.theme.background};
  position: relative;
  border-radius: ${(p) => p.theme.radius[400]};
  aspect-ratio: 3 / 4;
  overflow: hidden;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${(p) => p.theme.text[400]};
  padding: 0.25rem;
  z-index: 2;
  text-shadow: ${(p) => p.theme.shadow};
`;

const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: drop-shadow(${(p) => p.theme.shadow});
  border-radius: ${(p) => p.theme.radius[400]};
`;
