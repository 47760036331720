import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import GameCard from "./GameCard";
import Headline from "../copys/Headline";
import Text from "../copys/Text";
import games from "~~/games";
import Link from "../Link";

export default ({ slugs, headline, link }) => {
  return (
    <Wrapper>
      <HeadlineWrapper>
        {headline ? <Headline size="xs">{headline}</Headline> : null}
        {link?.copy ? (
          <Link to={link.to}>
            <LinkCopy size="xs">{link.copy}</LinkCopy>
          </Link>
        ) : null}
      </HeadlineWrapper>
      <SwiperWrapper>
        <StyledSwiper slidesPerView="auto">
          {slugs.map((slug) => (
            <StyledSwiperSlide key={slug}>
              <Link to={`/${slug}`}>
                <GameCard gameData={games[slug]} />
              </Link>
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .swiper-wrapper {
    display: flex;
    width: 100%;
  }
`;

const LinkCopy = styled(Text)`
  color: ${(p) => p.theme.accentBlue[400]};
`;

const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SwiperWrapper = styled.div``;

const StyledSwiper = styled(Swiper)`
  overflow: visible !important;
  margin-right: ${(p) => -Number.parseFloat(p.theme.padding.outer)}rem !important;
  display: flex;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  min-width: 31.333%;
  flex: 0.31333;
  padding-right: 1rem;
`;

/**  @include mq("md") {
    min-width: 24%;
    flex: 0.24;
  }

  @include mq("lg") {
    min-width: 19%;
    flex: 0.19;
  } */
